import * as React from "react"
import { AnimRight } from "src/components/animation.js"

const SubPageCompanyProfileList = (props) => {
    const listHtml = [];
    for (var i in props.list) {
        let listInnerHtml = [];
        let ddHtml = [];
        listInnerHtml.push(<dt key={'prof_dt_' + i} className="
            text-13px tracking-06em leading-none mb-3
            md:text-14px
            xl:text-15px xl:mb-7
        "><AnimRight key={'prof_AnimRight_dt_' + i}>{props.list[i].title}</AnimRight></dt>);
        for (var j in props.list[i].texts) {
            /*
            if (j !== '0') {
                ddHtml.push(<br key={'prof_span_br' + i + '_' + j} />);
            }
            */
            ddHtml.push(<AnimRight key={'prof_AnimRight_span_' + i + '_' + j}><span key={'prof_span_' + i + '_' + j}>{props.list[i].texts[j]}</span></AnimRight>);
        }
        listInnerHtml.push(<dd key={'prof_dd_' + i} className="
            text-15px tracking-06em leading-20px text-justify border-b border-company mb-3 pb-3
            md:text-17px md:leading-25px md:mb-7 md:pb-7
            xl:text-18px xl:leading-27px
            last:border-b-0 last:mb-0 last:pb-0
        ">{ddHtml}</dd>);
        listHtml.push(listInnerHtml);
    }
    return (
        <dl className={props.class} style={props.style}>
            {listHtml}
        </dl>
    );
};

export default SubPageCompanyProfileList;