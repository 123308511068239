import * as React from "react"
import { AnimRight } from "src/components/animation.js"

const SubPageCompanyHistoryList = (props) => {
    const listHtml = [];
    for (var i in props.list) {
        let listInnerHtml = [];
        let ddHtml = [];
        listInnerHtml.push(<dt key={'hstry_dt_' + i} className="
            text-company text-21px tracking-49em leading-none font-thin mb-2
            md:text-24px md:mb-4
            xl:text-36px xl:mb-6
        "><AnimRight key={'hstry_AnimRight_dt_' + i}>{props.list[i].year}<span className="
            text-13px
            md:text-14px
            xl:text-21px
        ">{'／' + props.list[i].detail[0].month + '月'}</span></AnimRight></dt>);
        for (var j in props.list[i].detail) {
            if (j !== '0') {
                ddHtml.push(<AnimRight key={'hstry_AnimRight_p_sub_' + i + '_' + j}><p key={'hstry_p_sub' + i + '_' + j} className="
                    text-company text-13px tracking-84em leading-none font-thin my-2
                    md:text-14px md:my-4
                    xl:text-21px xl:my-6
                ">{props.list[i].detail[j].month + '月'}</p></AnimRight>);
            }
            ddHtml.push(<AnimRight key={'hstry_AnimRight_p_' + i + '_' + j}><p key={'hstry_p_' + i + '_' + j}>{props.list[i].detail[j].text}</p></AnimRight>);
        }
        listInnerHtml.push(<dd key={'hstry_dd_' + i} className="
            text-13px tracking-06em leading-19px text-justify mb-11
            md:text-14px md:leading-25px md:mb-10
            xl:text-16px xl:leading-31px xl:mb-16
            last:mb-0
        ">{ddHtml}</dd>);
        listHtml.push(listInnerHtml);
    }
    return (
        <dl className={props.class} style={props.style}>
            {listHtml}
        </dl>
    );
};

export default SubPageCompanyHistoryList;