import * as React from "react"
import Layout from "src/components/layout"
import Meta from "src/components/meta"
import { PAGE_IDS } from "src/utils/exportFunctions"
import SubPageMv from "src/components/subpage/mv"
import SubPageCompanyHistoryList from "src/components/subpage/company/historyList"
import SubPageCompanyProfileList from "src/components/subpage/company/profileList"
import titleMsgImg from "src/images/comany/company_title_msg.svg"
import titleHstryImg from "src/images/comany/company_title_hstry.svg"
import titlePrflImg from "src/images/comany/company_title_prfl.svg"
import pfrlChartImg from "src/images/comany/company_prfl_chart.svg"
import StylesSubpageMv from "src/components/styles/subpage/mv"
import { AnimLeft, AnimRight, AnimUp } from "src/components/animation.js"

const CompanyPage = () => {
    const anchorList = [
        { 'text': '挨拶', 'anchorId': 'section_message' },
        { 'text': '沿革', 'anchorId': 'section_history' },
        { 'text': '会社概要', 'anchorId': 'section_profile' },
    ];
    const historyList = [
        {
            'year': '1979', 'detail': [
                { 'month': '7', 'text': '株式会社ライオン社広告部を発展・独立させ、東京都新宿区高田馬場にライオン企画株式会社として設立。ライオン社発行の『私大進学』専属広告代理店、私立大学入学願書委託販売等を主要業務にスタート。' },
            ]
        },
        {
            'year': '1981', 'detail': [
                { 'month': '5', 'text': '大阪市北区に関西支局を開設。' },
            ]
        },
        {
            'year': '1987', 'detail': [
                { 'month': '7', 'text': '第1回「私立大学フェア」を日本武道館にて開催（以降全国展開へ）' },
            ]
        },
        {
            'year': '1993', 'detail': [
                { 'month': '4', 'text': '関西支局を大阪支社に名称変更。' },
            ]
        },
        {
            'year': '1994', 'detail': [
                { 'month': '11', 'text': '現在の地に本社を移転。' },
            ]
        },
        {
            'year': '1997', 'detail': [
                { 'month': '5', 'text': '「福祉系大学・短期大学進学相談会」（現：ココロとカラダを考える進学相談会）を大阪市にて開催。' },
                { 'month': '11', 'text': '私立大学入学願書の全国通信販売を開始。' },
            ]
        },
        {
            'year': '2000', 'detail': [
                { 'month': '12', 'text': 'コンビニエンスストアの情報端末機等を利用した、大学入学検定料収納代行業務をスタート。' },
            ]
        },
        {
            'year': '2001', 'detail': [
                { 'month': '7', 'text': '「私立大学フェア」を「大学フェア」に改称。' },
            ]
        },
        {
            'year': '2004', 'detail': [
                { 'month': '1', 'text': '個人情報保護に関するコンプライアンス・プログラムの構築をスタート。' },
            ]
        },
        {
            'year': '2015', 'detail': [
                { 'month': '10', 'text': 'インターネット出願システム「E楽ネット出願」のサービス提供を開始。' },
            ]
        },
        {
            'year': '2017', 'detail': [
                { 'month': '8', 'text': 'インターネット出願システム「E楽Plus」のサービス提供を開始。' },
            ]
        },
    ];
    const profileList = [
        {
            'title': '代表取締役社長', 'texts': [
                '守谷　香也',
            ]
        },
        {
            'title': '設立', 'texts': [
                '1979年7月20日',
            ]
        },
        {
            'title': '資本金', 'texts': [
                '1980万円',
            ]
        },
        {
            'title': '本社所在地', 'texts': [
                '〒161-8520',
                '東京都新宿区中落合1-6-12',
            ]
        },
        {
            'title': '事業内容', 'texts': [
                '全国新聞・雑誌・テレビ・ラジオ・広告代理、PR動画・Webコンテンツ・印刷出版企画制作、イベント企画・運営、各種代行業務',
            ]
        },
        {
            'title': '取引銀行', 'texts': [
                'みずほ銀行高田馬場支店',
                '三菱UFJ銀行高田馬場支店',
            ]
        },
        {
            'title': '取引先', 'texts': [
                '全国の国公私立大学・短期大学、各種専門学校、予備校、全国テレビ局・新聞社、株式会社ライオン社　他',
            ]
        },
    ];
    return (
        <Layout>
            <Meta
                title="会社概要"
                desc="ライオン企画の会社概要ページです"
            />
            <StylesSubpageMv />
            <SubPageMv pageId={PAGE_IDS['company']} anchorList={anchorList} />
            <section id="section_message" className="
                w-11/12 ml-0 mb-56
                md:w-md md:mx-auto md:mb-48
                xl:w-xl xl:max-w-1000px xl:mb-60
            ">
                <div className="flex justify-between">
                    <div className="
                        w-1/12
                        md:w-1/12
                    ">
                        <AnimLeft margin="-5%"><h2><img src={titleMsgImg} alt='MESSAGE' /></h2></AnimLeft>
                    </div>
                    <div className="
                        font-serif text-justify w-10/12 font-normal
                        md:w-5/6
                        xl:min-w-7xl
                    ">
                        <AnimRight>
                            <h3 className="
                                text-16px tracking-25em leading-36px mb-12
                                md:text-21px md:leading-50px md:mb-16
                                xl:text-22px xl:tracking-24em xl:leading-55px xl:mb-24
                            ">「ライオン企画株式会社は自社の個性を大切にし、<br className="hidden xl:inline" />当社ならではの教育情報サービス・教育ビジネスの活動を通して、<br className="hidden xl:inline" />若者の未来創造を応援し、社会の発展に貢献します。」</h3>
                        </AnimRight>
                        <AnimRight>
                            <div className="
                                text-13px tracking-09em leading-23px
                                md:text-15px md:leading-35px
                            ">
                                <p className="mb-10 md:mb-14">ライオン企画株式会社は1979年の設立以来、クライアントの要望に対し、懇切丁寧に対応する姿勢を基本として企画や商品を販売・運営すること、高品質なサービスを提供することでクライアントから常に指名される会社となることを目指し企業活動を続けてまいりました。<br />私はこの“懇切丁寧に対応する姿勢”こそ、弊社の個性であると考えております。<br />「ライオン企画に頼んで良かった。」「ライオン企画と仕事をすると楽しい。」「ライオン企画と仕事がしたい。」<br />クライアントにそう言われることは私たちの誇りであり、そのためには『懇切丁寧』さと『高品質』を目指す姿勢が不可欠だと思います。</p>
                                <p className="mb-10 md:mb-14">そして“時代の変化に対応できる組織であること”が弊社のもう一つの個性です。<br />いまや当たり前となったインターネット出願、コンビニエンスストアやクレジットカードでの入学検定料の支払い、Web上での合否照会等についても、弊社はその黎明期から携わってきました。今後も、こうしたシステムを全国の大学に普及することは弊社の使命であると考えています。<br />また、社会が自分でキャリア・プランを立てて実行していく時代へと変化している中で、大学入学をゴールと捉えた大学受験情報の提供ではなく、キャリア教育のコンテンツを加えライフキャリアとして考えた、若者の夢実現につながる大学選択のための情報を多角的提供していきたいと考えています。</p>
                                <p className="mb-10 md:mb-14">そして、保護者の関わり方の提案。受験生本人の主体性を重要としながらも、若さや経験の不足から方向を見誤らないように、ライフプランニングの良き相談相手、アドバイザーとして機能するように保護者を導いていく情報提供をしていきたいと考えています。</p>
                                <p className="mb-10 md:mb-12 xl:mb-20">“懇切丁寧に対応する姿勢”、“時代の変化に対応できる組織であること”に“クライアントの利益に貢献できるプロフェッショナルな提案ができること”を加えた3つを弊社の個性と捉え大切にし、弊社らしい企業活動を続けていくことで若者の未来創造を応援し、社会の発展に貢献していきたいと考えております。</p>
                            </div>
                        </AnimRight>
                        <AnimRight>
                            <div className="
                                text-15px tracking-67em leading-25px font-bold
                                md:text-20px md:leading-38px
                            ">
                                <p>代表取締役社長 <br className="md:hidden" />守谷香也</p>
                            </div>
                        </AnimRight>
                    </div>
                </div>
            </section>

            <section id="section_history" className="
                w-11/12 ml-0 mb-56
                md:w-md md:mx-auto md:mb-48
                xl:w-xl xl:max-w-1000px xl:mb-60
            ">
                <div className="flex justify-between">
                    <div className="
                        w-1/12
                        md:w-1/12
                    ">
                        <AnimLeft margin="-5%"><h2><img src={titleHstryImg} alt='HISTORY' /></h2></AnimLeft>
                    </div>
                    <div className="
                        w-10/12
                        md:w-5/6
                        xl:min-w-7xl
                    ">
                        <AnimRight>
                            <h3 className="
                                text-15px tracking-27em leading-none mb-7
                                md:text-20px md:tracking-35em md:mb-12
                                xl:text-30px xl:mb-20
                            ">沿革</h3>
                        </AnimRight>
                        <SubPageCompanyHistoryList list={historyList} />
                    </div>
                </div>
            </section>
            <section id="section_profile" className="
                w-11/12 ml-0 mb-14
                md:w-md md:mx-auto md:mb-32
                xl:w-xl xl:max-w-1000px xl:mb-36
            ">
                <div className="
                    flex justify-between mb-24
                    md:mb-18
                    xl:mb-40
                ">
                    <div className="
                        w-1/12
                        md:w-1/12
                    ">
                        <AnimLeft margin="-5%"><h2><img src={titlePrflImg} alt='PROFILE' /></h2></AnimLeft>
                    </div>
                    <div className="
                        w-10/12
                        md:w-5/6
                        xl:min-w-7xl
                    ">
                        <SubPageCompanyProfileList list={profileList} />
                    </div>
                </div>
                <div className="
                    w-11/12 ml-auto mr-0
                    md:w-full
                ">
                    <AnimUp><img src={pfrlChartImg} alt='組織図' width="1002" height="432" /></AnimUp>
                </div>
            </section>
        </Layout>
    )
}

export default CompanyPage
